<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
           <div class="page-title-right" v-if="viewForm">
            <router-link
              :to="{
                name: 'grading-level-management',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>

          <h4 class="page-title">
            {{
              currentRouteName == "grading-level-management-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="row mb-3">
                  <label for="name" class="col-md-3 form-label">
                    Program Name
                    <span class="text-danger">*</span>
                  </label>
                  <div class="col-md-8" v-if="hasPermissions(['grading-level-create', 'grading-level-edit'])">
                    <v-select
                      @option:selected="getSelectedProgram"
                      v-model="selectedProgram"
                      :disabled="viewForm"
                      :selectable="(options) => isSelectable(options)"
                      label="name"
                      :options="programs"
                      :clearable="false"
                      :class="{
                        'p-invalid':
                          v$.selectedProgram.$error || errorFor('name'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :serverErrors="errorFor('name')"
                      :vuelidateErrors="{
                        errors: v$.selectedProgram.$errors,
                        value: 'Program Name',
                      }"
                    ></v-errors>
                  </div>

                  <div class="col-md-8" v-else-if="hasPermissions(['grading-level-list'])">
                    <v-select
                      v-model="selectedProgram"
                      label="name"
                      :clearable="false"
                      disabled
                    >
                    </v-select>
                  </div>

                </div>
              </div>

              <div class="row mt-1" v-for="(item, index) in grade" :key="index">
                <div>
                  <h4>{{ item.name }}</h4>
                  <div class="row" v-if="hasPermissions(['grading-level-create', 'grading-level-edit'])">
                    <div class="col-5">
                      <div>
                        <div class="text-end font-10 fw-bold">Lowest Score</div>
                        <div>
                          <input
                            type="number"
                            class="form-control"
                            :disabled="viewForm"
                            v-model="item.lowest_score"
                            @input="lowScoreChanges($event.target.value, index)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="ms-5 col-5">
                      <div class="text-end font-10 fw-bold">Highest Score</div>
                      <div>
                        <input
                          type="number"
                          class="form-control"
                          :disabled="viewForm"
                          v-model="item.highest_score"
                          @input="highScoreChanges($event.target.value, index)"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row" v-else-if="hasPermissions(['grading-level-list'])">
                    <div class="col-5">
                      <div>
                        <div class="text-end font-10 fw-bold">Lowest Score</div>
                        <div>
                          <input
                            type="number"
                            class="form-control"
                            disabled
                            v-model="item.lowest_score"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- @input="highScoreChanges($event.target.value, index)" -->
                    <div class="ms-5 col-5">
                      <div class="text-end font-10 fw-bold">Highest Score</div>
                      <div>
                        <input
                          type="number"
                          class="form-control"
                          v-model="item.highest_score"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

                <div
                class="row mb-3 mt-4 text-center"

                v-if="currentRouteName === 'grading-level-management-update'"
              >
                <label for="status" class="col-md-3 form-label">
                  Status
                </label>
                <div class="col-md-6" v-if="hasPermissions(['grading-level-create', 'grading-level-edit'])">
                  <select name="status" v-model="status" class="form-control" :disabled="viewForm">
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>

                <div class="col-md-6" v-else-if="hasPermissions(['grading-level-list'])">
                  <select name="status" v-model="status" class="form-control" disabled>
                    <option :value="1">Active</option>
                    <option :value="0">Inactive</option>
                  </select>
                </div>
              </div>

               <div class="row mb-3 text-center" v-if="status == 0">
                <label for="remark" class="col-md-3 form-label">
                  Remark
                  <!-- <span class="text-danger">*</span> -->
                </label>
                <div class="col-md-6" v-if="hasPermissions(['grading-level-create', 'grading-level-edit'])">
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    :disabled="viewForm"
                    placeholder="Remark"
                    v-model="remark"
                    :class="{
                      'p-invalid': v$.remark.$error || errorFor('remark'),
                    }"
                  >
                  </textarea>
                  <v-errors
                    :serverErrors="errorFor('remark')"
                    :vuelidateErrors="{
                      errors: v$.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors>
                </div>

                <div class="col-md-6" v-else-if="hasPermissions(['grading-level-list'])">
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    class="form-control"
                    placeholder="Remark"
                    v-model="remark"
                    disabled
                  >
                  </textarea>
                </div>
              </div>

              <div class="row mt-3" v-if="!viewForm || hasPermissions(['grading-level-create', 'grading-level-edit'])">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'grading-level-management' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'grading-level-management-update'
                          ? updateGradingLevel()
                          : createGradingLevel()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: this.$store.getters["odoo/getAllPrograms"],
      status: "1",
      remark: "",
      viewForm: false,
      existedProgram: [],
      grade: [
        {
          name: "Grade A",
          lowest_score: 0,
          highest_score: 0,
        },
        {
          name: "Grade B",
          lowest_score: 0,
          highest_score: 0,
        },
        {
          name: "Grade C",
          lowest_score: 0,
          highest_score: 0,
        },
        {
          name: "Grade D",
          lowest_score: 0,
          highest_score: 0,
        },
        {
          name: "Grade E",
          lowest_score: 0,
          highest_score: 0,
        },
      ],
      selectedProgram: null,
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      remark: {
        required: requiredIf(() => {
          return this.status != 1;
        }),
      },
    };
  },
  methods: {

    isSelectable(options) {
      if (this.selectedProgram) {
                const programExist = this.existedProgram.some((item) => item.program_id == this.selectedProgram.id)
                // If the batch doesn't exist, add it to existedProgram
                if (!programExist) {
                    this.existedProgram = this.existedProgram.filter(
                        (item) => !item.is_selected
                    );
                    this.existedProgram.push({
                      program_id: this.selectedProgram.id,
                      is_selected: true,
                    });
                }
            }
            // Now, check if the options.id exists in existedProgram
            return !this.existedProgram.find(
                (item) => item.program_id == options.id 
            );
      // return (
      //   this.existedProgram.find(
      //     (item) =>
      //       item.program_id == options.id 
      //   ) === undefined
      // );
    },
    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedBatch = null;
        // this.clearData();

        // this.getBatchByProgram(this.behavior.program_id);
      }
    },

    lowScoreChanges(val, index) {
      this.grade[index].lowest_score =
        val && Math.abs(val) <= 100 ? Math.abs(val) : null;
      return this.grade[index].lowest_score;
    },

    highScoreChanges(val, index) {
      this.grade[index].highest_score =
        val && Math.abs(val) <= 100 ? Math.abs(val) : null;
      return this.grade[index].highest_score;
    },

    async createGradingLevel() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;

      const resultObject = {};

      this.grade.forEach((item, index) => {
        // Convert the index to the corresponding key
        const key = `g_${String.fromCharCode(97 + index)}`;

        // Create a new object with the desired structure
        resultObject[key] = {
          lowest_score: item.lowest_score,
          highest_score: item.highest_score,
        };
      });

      const toSendData = {
        program_id: this.selectedProgram.id,
        program_name: this.selectedProgram.name,
        g_a: resultObject.g_a,
        g_b: resultObject.g_b,
        g_c: resultObject.g_c,
        g_d: resultObject.g_d,
        g_e: resultObject.g_e,
      };

      await axios
        .post(`${this.baseUrl}admin/v2/grading-level`, toSendData)
        .then(() => {
          this.$router.push({ name: "grading-level-management" });
          this.toast.success("Successfully created grading level.");
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
        });
      this.isLoading = false;
    },

    async getGradingLevelManagementById() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/grading-level/${this.$route.params.id}`)
        .then((response) => {
          // console.log(response.data.data)
          const data = response.data.data;
          this.selectedProgram = {
            id: data.program_id,
            name: data.program_name,
          };
          const gradingResult = {
            g_a: data.g_a,
            g_b: data.g_b,
            g_c: data.g_c,
            g_d: data.g_d,
            g_e: data.g_e,
          };

          const resultArray = Object.keys(gradingResult).map((key) => ({
            name: `Grade ${key.substring(2).toUpperCase()}`,
            lowest_score: gradingResult[key].lowest_score,
            highest_score: gradingResult[key].highest_score,
          }));

          this.grade = resultArray;
          this.status = data.status;
          if(this.status == 0) this.viewForm = true;
          this.remark = data.remark;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong.");
        });
      this.loading = false;
    },

    async updateGradingLevel() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;

      const resultObject = {};

      this.grade.forEach((item, index) => {
        // Convert the index to the corresponding key
        const key = `g_${String.fromCharCode(97 + index)}`;

        // Create a new object with the desired structure
        resultObject[key] = {
          lowest_score: item.lowest_score,
          highest_score: item.highest_score,
        };
      });

      const toSendData = {
        program_id: this.selectedProgram.id,
        program_name: this.selectedProgram.name,
        g_a: resultObject.g_a,
        g_b: resultObject.g_b,
        g_c: resultObject.g_c,
        g_d: resultObject.g_d,
        g_e: resultObject.g_e,
        status: this.status,
        remark: this.remark
      };

      await axios
        .put(`${this.baseUrl}admin/v2/grading-level/${this.$route.params.id}`, toSendData)
        .then(() => {
          this.$router.push({ name: "grading-level-management" });
          this.toast.success("Successfully updated grading level.");
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
        });
      this.isLoading = false;
    },

    async getExistedProgram()
    {
      this.loading = true;
      await axios.get(`${this.baseUrl}admin/v2/grading-level-get-existed-program`)
            .then((response) => {
              this.existedProgram = response.data.data;
            })
            .catch((error) => {
              this.error = error.response;
              this.toast.error('Something went wrong.');
            })
            this.loading = false;
    }

  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  created() {
    this.getExistedProgram();
    if (this.currentRouteName == "grading-level-management-update") {
      this.getGradingLevelManagementById();
    }
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
</style>
